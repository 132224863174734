


































































import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import ProductCard from '~/components/molecules/Product/ProductCard.vue';
import MediumProductCard from '~/components/molecules/Product/MediumProductCard/MediumProductCard.vue';
import { ProductTag } from '~/types/product/attribute/ProductTag';
import ProductDimensionsInterface from '~/types/product/attribute/ProductDimensions';
import { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import type { ProductCategories } from '~/types/product/ProductCategories';

export default defineComponent({
  name: 'ProductCardWrapper',
  components: {
    ProductCard,
    MediumProductCard,
    MiniProductCard: () => import(/* webpackChunkName: "MiniProductCard" */
      '~/components/molecules/Product/MiniProductCard/MiniProductCard.vue')
  },
  props: {
    isMini: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    discountedPrice: {
      type: Number,
      default: null
    },
    sku: {
      type: String,
      required: true
    },
    reviewsCount: {
      type: Number,
      default: 0
    },
    rating: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: ''
    },
    tags: {
      type: Array as PropType<ProductTag[]>,
      default: () => {
        return [];
      }
    },
    brand: {
      type: String,
      default: ''
    },
    dimensions: {
      type: Object as PropType<ProductDimensionsInterface>,
      default: () => {
        return { hasDimensions: false };
      }
    },
    priceGuaranteeUrl: {
      type: String,
      required: true
    },
    stockCount: {
      type: Number,
      required: true
    },
    parcelDeliveryDate: {
      type: String,
      default: ''
    },
    freightDeliveryDate: {
      type: String,
      default: ''
    },
    isFreight: {
      type: Boolean,
      default: false
    },
    keyFacts: {
      type: Array as PropType<String[]>,
      default: () => []
    },
    releaseDate: {
      type: String,
      required: true
    },
    energyRating: {
      type: Object as PropType<EnergyRatingDetails>,
      default: () => ({})
    },
    currency: {
      type: String,
      required: true
    },
    categories: {
      type: [Object, String] as PropType<ProductCategories>,
      required: true
    },
    productBoxTypeTag: {
      type: String,
      required: true
    }
  },
  setup(_, { emit }) {
    const onClick = () => {
      emit('product-clicked');
    };

    return {
      onClick
    };
  }
});
