






















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { LinkType } from '~/types/components/Link/LinkType';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { useIsMounted, useUiMode } from '~/composables';

const PRODUCT_SLIDER_SIZE = {
  MINI: 'mini',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export default defineComponent({
  name: 'ProductSlider',
  components: {
    SimpleCarousel
  },
  props: {
    sliderItems: {
      type: Array,
      default: () => []
    },
    hasControlsOutsideContainer: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isMini: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 4
    }
  },
  setup(props) {
    const { isMobile } = useUiMode();
    const { isMounted } = useIsMounted();

    const wrapperClasses = computed(() => `mx-auto${props.isFullWidth ? '' : ' lg:max-w-relatedProducts'}`);
    const size = computed(() => {
      if (props.isMini) {
        return PRODUCT_SLIDER_SIZE.MINI;
      }
      if (!isMobile.value && isMounted.value) {
        return PRODUCT_SLIDER_SIZE.LARGE;
      }
      return PRODUCT_SLIDER_SIZE.MEDIUM;
    });

    const productCardWidths = {
      [PRODUCT_SLIDER_SIZE.MINI]: 150,
      [PRODUCT_SLIDER_SIZE.MEDIUM]: 229,
      [PRODUCT_SLIDER_SIZE.LARGE]: 337
    };
    const productCardWidth = computed(() => productCardWidths[size.value]);

    const productCardGaps = {
      [PRODUCT_SLIDER_SIZE.MINI]: 16,
      [PRODUCT_SLIDER_SIZE.MEDIUM]: 16,
      [PRODUCT_SLIDER_SIZE.LARGE]: 30
    };
    const productSliderGap = computed(() => productCardGaps[size.value]);

    return {
      LinkType,
      wrapperClasses,
      productCardWidth,
      productSliderGap
    };
  }
});

