














































import { defineComponent, onMounted, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import { useRouteBestPriceGuarantee, useProductDeliveryDate } from '~/composables';
import RelatedProduct from '~/components/organisms/Product/RelatedProducts/RelatedProduct.vue';
import RelatedBanner from '~/components/organisms/Product/RelatedProducts/RelatedBanner.vue';
import ProductAnchorTitle from '~/components/atoms/Product/ProductAnchorTitle/ProductAnchorTitle.vue';
import { RelatedBanner as RelatedBannerType } from '~/types/components/RelatedBanner/RelatedBanner';
import ProductSlider from '~/components/organisms/Product/ProductSlider/ProductSlider.vue';

export default defineComponent({
  name: 'RelatedProducts',
  components: {
    RelatedProduct,
    Link,
    ProductAnchorTitle,
    RelatedBanner,
    ProductSlider
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    products: {
      type: Array as PropType<ProductVariant[]>,
      default: () => {
        return [];
      }
    },
    seeAllLinkText: {
      type: String,
      default: null
    },
    seeAllLinkSlug: {
      type: String,
      default: null
    },
    hasBanner: {
      type: Boolean,
      default: false
    },
    banner: {
      type: Array as PropType<RelatedBannerType[]>,
      default: () => {
        return [];
      },
      validator: (bannerItems: RelatedBannerType[]) => {
        return bannerItems.every((banner: RelatedBannerType) => {
          return banner.header && banner.description && banner.buttonLink && banner.buttonText && banner.image;
        });
      }
    },
    hasControlsOutsideContainer: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isMini: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { priceGuaranteeUrl } = useRouteBestPriceGuarantee();
    const { search: searchProductDeliveryDate, parcelDeliveryDate, freightDeliveryDate } = useProductDeliveryDate(true);

    onMounted(() => {
      searchProductDeliveryDate();
    });

    const sliderItems = computed(() => props.hasBanner ? [{ hasBanner: true }, ...props.products] : props.products);
    const wrapperClasses = computed(() => `mx-auto${props.isFullWidth ? '' : ' lg:max-w-relatedProducts'}`);

    const relatedProductClicked = (sku: string) => {
      emit('product-clicked', sku);
    };

    return {
      LinkType,
      priceGuaranteeUrl,
      parcelDeliveryDate,
      freightDeliveryDate,
      sliderItems,
      wrapperClasses,
      relatedProductClicked
    };
  }
});

